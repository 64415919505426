import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TranslatedPageTemplate from '../components/TranslatedPageTemplate'

const TranslatedPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <TranslatedPageTemplate
        languages={page.frontmatter.languages}
      />
    </Layout>
  )
}

TranslatedPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TranslatedPage

export const translatedPageQuery = graphql`
  query TranslatedPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        languages {
          language
          title
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
