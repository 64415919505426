import React from 'react'
import {HTMLContent} from './Content'
//import PropTypes from 'prop-types'

class DocumentPageTemplate extends React.Component {
  constructor(props) {
    super()
    this.languages = props.languages
    
    this.state = {
      langSet: 0,
    }


  }

  setLang(val) {
    this.setState({ langSet: val})
  }

  getNavigation = () => {
    if(this.languages.length > 1 ) {
      return (
        < >
          {this.languages.map( (node, index) => (
        <li key={index} onClick={() => ( this.setLang(index) )} className={index === this.state.langSet ? "is-active" : null}><a>{node.language}</a></li>
          ))}
        </ >
      )
    } else {
      return null;
    }
  }


  getContent = () => {
    return (
      <HTMLContent className="content" content={this.languages[this.state.langSet].content.childMarkdownRemark.html} />
    )
  }

  getTitle = () => {
    return (
      <h1 className="is-title is-size-1">{this.languages[this.state.langSet].title}</h1>
    )
  }

  render() {



  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <this.getTitle />
          </div>
        </div>

        <div className="tabs is-centered">
          <ul>
            <this.getNavigation />
          </ul>
        </div>

        <div className="columns">
          <div className="column is-7 is-offset-1">
            <this.getContent />
          </div>
        </div>
      </div>
    </section>
  )
  }
} 


export default DocumentPageTemplate
